<template>
  <b-table
    responsive
    :items="filteredProducts"
    :fields="computedFields"
  >
    <template #cell(is_express)="data">
      <h6 class="h-100 m-0">
        <b-badge
          v-if="quote.is_express"
          :id="`tooltip-express-${data.item.id}`"
          pill
          :variant="data.item.is_express ? 'success' : 'secondary'"
        >{{ data.item.is_express ? $t('express') : $t('ordinary') }}</b-badge>
      </h6>
    </template>
    <template v-slot:[`cell(product_catalog.name)`]="data">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column ml-1">
          <span class="">
            {{ data.value }}
          </span>
          <span
            class="text-muted"
            style="font-size: 12px;"
          >
            {{ data.item.product_catalog.brand }}
          </span>
        </div>
        <span class="d-flex">
          <b-tooltip
            :target="`tooltip-express-${data.item.id}`"
            triggers="hover"
            boundary-padding="10"
          >
            <span>
              Entrega promedio en
              {{ data.item.is_express.is_express ? '24' : '72' }} Hrs.
            </span>
          </b-tooltip>
        </span>
      </div>
    </template>
    <template #cell(supplier)="data">
      <h6 class="h-100 m-0">
        <b-badge
          class="h-100 text-capitalize"
          variant="primary"
        >
          {{ data.item.supplier }}
        </b-badge>
      </h6>
    </template>
    <template #cell(unit_price)="data">
      <!-- S/IVA -->
      $
      {{
        (Number(data.item.unit_price) +
          Number(data.item.unit_commission_price))
          | money
      }}
    </template>
    <template #cell(unit_price_without_commission)="data">
      <!-- S/IVA -->
      $
      {{ data.item.unit_price | money }}
    </template>
    <template #cell(quantity)="data">
      {{ data.item.quantity }}
    </template>
    <template #cell(unit_type)="data">
      <div>
        {{
          $tc(
            unitsFilter(data.item.product_catalog.unit_type),
            data.item.quantity,
          )
        }}
      </div>
    </template>
    <template #cell(total_without_commission)="data">
      ${{ data.value | money }}
    </template>
    <template #cell(total)="data">
      ${{ data.value | money }}
    </template>
    <template #cell(delete)="data">
      <b-button
        v-if="!data.item.is_express"
        size="sm"
        variant="danger"
        class="d-flex custome-button"
        @click="deleteItem(data.item)"
      >
        <feather-icon
          icon="TrashIcon"
          class="custom-margin"
        />
      </b-button>
    </template>
    <template #cell(whatsapp)="data">
      <div class="d-flex justify-content-center align-items-center">
        <!-- Administrador: Campo de texto para capturar número de pedido -->
        <div v-if="!data.item.order_number && currentUser.role_name === 'admin'">
          <input
            type="text"
            class="form-control"
            v-model="data.item.tempOrderNumber"
            placeholder="Número de pedido"
          />
          <b-button
            size="sm"
            variant="success"
            class="ml-2"
            @click="saveOrderNumber(data.item)"
          >
            Guardar
          </b-button>
        </div>

        <!-- Cliente: Mensaje si no hay número de pedido -->
        <div v-else-if="!data.item.order_number && currentUser.role_name !== 'admin'">
          <span class="text-muted">Completa la compra</span>
        </div>

        <!-- Ícono de WhatsApp: Visible solo si hay número de pedido -->
        <a
          v-else
          :href="`https://wa.me/52${getPhone(data.item).phone}?text=${encodeURIComponent(getPhone(data.item).message)}`"
          target="_blank"
          class="whatsapp-icon-link"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24" fill="#25D366">
            <path d="M380.9 97.1C339.3 55.5 285.4 32 224.1 32 109.5 32 16 125.5 16 240c0 45.6 12.2 90.1 35.3 129.1L0 480l112.2-48.1c37.7 20.5 80.2 31.5 123.9 31.5 114.6 0 208.1-93.5 208.1-208.1 0-61.2-23.9-115.2-67.3-158.3zM224.1 438.5c-38.5 0-76-10.5-108.6-30.4l-7.8-4.7-66.9 28 28.6-65.4-5.1-8.3c-21.3-35.2-32.5-75.7-32.5-117.6 0-123 100-223 223-223 59.9 0 116.2 23.3 158.5 65.6s65.6 98.5 65.6 158.5c0 123-100 223-223 223zm124.3-146.1l-27.8-13c-15.2-7.1-26.3-13-37.6 2.2-10.8 14.6-20.2 26.7-34.5 21.1-38.4-15.4-77.6-43.1-102.4-76.6-11-14.8 11-13.7 25.3-45.8 2.5-5.4 1.2-10.1-0.6-14.1l-12-28.2c-2.9-6.7-5.9-12.7-14.6-10.8-10.6 2.3-33.1 19.9-39.1 39.1-7.8 25.5-1.7 58.3 35.9 95.3 41.7 41.2 81.7 64.7 121.4 78.1 12.6 4.4 24 3.8 33-3.7 10.4-8.6 23.2-27.2 35.7-43.6 5.6-7.3 11.5-10.3 18.6-10.3 6.3 0 13.9-0.3 21.3 3.3 8.5 4.3 19.9 9.9 30.4 14.9 8.6 4.1 16 4.9 21.7-4.2 5.1-8.2 5.1-20.1 3.6-23.7-1.5-3.6-8.2-5.9-16.5-10.2z"/>
          </svg>
        </a>
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  props: {
    products: {
      type: Array,
      default() {
        return []
      },
    },
    supplierData: {
      type: Array,
      default() {
        return []
      },
    },
    projectData: {
      type: Array,
      default() {
        return []
      },
    },
    quoteData: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'is_express',
          label: this.$t('delivery'),
        },
        {
          key: 'product_catalog.name',
          label: this.$t('products'),
        },
        {
          key: 'supplier',
          label: this.$t('supplier'),
        },
        {
          key: 'whatsapp',
          label: this.$t('whatsapp'),
        },
        {
          key: 'quantity',
          label: this.$t('quantity'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'unit_type',
          label: this.$t('unit'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'unit_price',
          label: this.$t('unitPrice'),
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'total',
          label: this.$t('total'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters('app', ['unitsFilter']),
    ...mapGetters('quotes', ['quote']),
    ...mapGetters('auth', ['currentUser']),
    filteredProducts() {
      const filtered = this.products.filter(
        product => product.selected_supplier_branch_id && product.active_status,
      )
      return filtered
    },
    computedFields() {
      const fields = [...this.fields]

      // Incluir columna "eliminar" si es express
      if (this.quote.is_express) {
        fields.push({
          key: 'delete',
          label: this.$t('delete'),
        })
      } else {
        // Eliminar columna "eliminar" si no es express
        const deleteIndex = fields.findIndex(field => field.key === 'delete')
        if (deleteIndex !== -1) {
          fields.splice(deleteIndex, 1)
        }
      }

      // Incluir o eliminar columna "Entrega" (is_express)
      const deliveryIndex = fields.findIndex(field => field.key === 'is_express')
      if (this.quote.is_express) {
        // Si la columna "Entrega" no está, la añadimos
        if (deliveryIndex === -1) {
          fields.unshift({
            key: 'is_express',
            label: this.$t('delivery'),
          })
        }
      } else if (deliveryIndex !== -1) {
        // Si no es express, eliminamos la columna "Entrega"
        fields.splice(deliveryIndex, 1)
      }

      return fields
    },
  },

  created() {
    // If view is purchase order and currentUser is auctioneer we replace: (total => total without comission)
    if (
      this.$route.name === 'purchaseOrder'
      && ['auctioneer', 'supplier_admin'].includes(this.currentUser.role_name)
    ) {
      const totalWithoutCommission = {
        key: 'total_without_commission',
        label: this.$t('total'),
        tdClass: 'text-center',
        thClass: 'text-center',
      }
      const unitPriceWithoutCommission = {
        key: 'unit_price_without_commission',
        label: this.$t('unitPrice'),
        tdClass: 'text-center',
        thClass: 'text-center',
      }
      const indexToDelete1 = this.fields.findIndex(f => f.key === 'total')
      const indexToDelete2 = this.fields.findIndex(f => f.key === 'unit_price')
      this.fields[indexToDelete1] = totalWithoutCommission
      this.fields[indexToDelete2] = unitPriceWithoutCommission
      // this.fields.splice(indexToDelete1, 0, elementToInsert)
    }
    if (this.$route.name === 'checkout') {
      const deleteColumn = {
        key: 'delete',
        label: this.$t('delete'),
      }
      this.fields.push(deleteColumn)
    }
  },
  methods: {
    ...mapActions('quotes', ['deleteQuoteItems', 'fetchQuote', 'updateOrderNumber']),
    async deleteItem(product) {
      const items = [{ quote_item_id: product.id }]
      await this.deleteQuoteItems({
        quoteId: this.quote.id,
        items,
      })
      this.$emit('delete-user')
    },
    async saveOrderNumber(item) {
      if (!item.tempOrderNumber || item.tempOrderNumber.trim() === '') {
        alert('Por favor, ingresa un número de pedido válido.')
        return
      }

      try {
        // Llamar a la acción Vuex
        await this.updateOrderNumber({
          quoteId: this.quoteData.id, // ID de la cotización
          quoteItemId: item.id, // ID del QuoteItem
          orderNumber: item.tempOrderNumber.trim(),
        })

        // Actualiza localmente el número de pedido
        item.order_number = item.tempOrderNumber.trim()
        item.tempOrderNumber = ''
        alert('Número de pedido guardado correctamente.')
      } catch (error) {
        console.error('Error guardando el número de pedido:', error)
        alert('Hubo un problema al guardar el número de pedido.')
      }
    },
    getPhone(item) {
      const foundSupplier = this.supplierData.find(s => s.id === item.selected_supplier_branch_id)
      if (foundSupplier) {
        const orderNumber = item.order_number // Recuperar número de pedido

        const message = `Solicito estatus de ORDEN "${orderNumber}" DE PLATAFORMA DIGITAL YOC`

        return {
          phone: foundSupplier.phone,
          message,
        }
      }
      return { phone: null, message: '' }
    },
  },
}
</script>

<style>
.whatsapp-icon-link svg {
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease;
}

.whatsapp-icon-link:hover svg {
  transform: scale(1.2);
  filter: brightness(1.2);
}
</style>
